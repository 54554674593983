import React, { useContext, useState, useMemo, useCallback } from "react";
import { Route, Switch, Redirect, useHistory } from "react-router-dom";

import { ConfirmPopUp } from "common-components-spa";
import { AppContext } from "src/App";
import UserPreference from "src/page/userPreference/UserPreference";
import UserProfile from "src/page/userProfile/UserProfile";
import ChangePassword from "src/page/ChangePassword/ChangePassword";

// User Routes
import { userRoute } from "../../constants/config";

const UserWrapper = () => {
  // getting values from context
  const {
    translateHTML: tHTML,
    setToast,
    translate: t,
    setProgressPopupState,
    errorPopup,
    unAuthorizedErrorMessage,
    selectedSpaId,
    defaultSpaId,
    setSelectedSpaId,
    activeHubInfo
  } = useContext(AppContext);

  // initial progress popup configuration
  const initialprogressConfig = {
    headerContent: t("Please wait while we updating your details"),
    status: true,
    progress: true,
    animationDuration: 0.5,
    done: false,
  };

  const isActiveHub = useMemo(() => {
    if('isActiveHub' in activeHubInfo && activeHubInfo.isActiveHub) {
      return true;
    }
    return false;
  }, [activeHubInfo]);

  // redirect the routes
  const history = useHistory();

  //confirmation popup
  const [isComfirmPopupVisible, setConfirmedVisible] = useState(false);

  const setLoaderState = (isVisible) => {
    // setting the progress popup state
    setProgressPopupState({
      ...initialprogressConfig,
      isOpen: !!isVisible,
    });
  };

  const handleConfirmed = () => {
    // cancel confirmed
    setConfirmedVisible(false);
    setSelectedSpaId(defaultSpaId);  
  };

  const handleCanceled = () => {
    // cancel popup no button
    setConfirmedVisible(false);
  };

  // handling error message visibilty
  const handlePopButton = useCallback(() => {
    errorPopup.setVisibility(false);
  }, [errorPopup]);

  // Error Messages
  const errorMessage = useMemo(() => {
    return {
      unAuthorized: { ...unAuthorizedErrorMessage },
      createContainerRetry: {
        heading: t("Oops!! Something went wrong"),
        subInfo: t(
          "We are unable to Update Details. Do you want to try again?"
        ),
        image: `${process.env.PUBLIC_URL}/images/generic-error-icon.svg`,
        btnName: t("Try Again"),
        btnStyle: true,
        onClosePopup: handlePopButton,
        cancelButtonAction: handlePopButton,
      },
      createContainerError: {
        heading: t("Oops!! Something went wrong"),
        subInfo: t("We are unable to update details."),
        image: `${process.env.PUBLIC_URL}/images/generic-error-icon.svg`,
        btnName: t("Okay, Got it"),
        btnStyle: true,
        onClosePopup: handlePopButton,
        cancelButtonAction: handlePopButton,
      },
      apiError: (apiMessage) => ({
        heading: apiMessage,
        image: `${process.env.PUBLIC_URL}/images/generic-error-icon.svg`,
        btnName: t("Okay, Got it"),
        btnStyle: true,
        onClosePopup: handlePopButton,
        cancelButtonAction: handlePopButton,
      }),
    };
  }, [handlePopButton, t, unAuthorizedErrorMessage]);

  // show error on API failure
  const showErrorPopup = ({ type, retryAction, apiMessage, params = [] }) => {
    const errorConfig = () => {
      if (type === "apiError") {
        return errorMessage[type](apiMessage);
      } else {
        return errorMessage[type];
      }
    };

    errorPopup.setState({
      ...errorConfig(),
      buttonAction: () => {
        if (retryAction) {
          retryAction(...params);
        }
        errorPopup.setVisibility(false);
      },
    });

    errorPopup.setVisibility(true);
  };

  // handling API error
  const handleAPIError = ({ error, callback }) => {
    if (error && error.status === 403) {
      showErrorPopup({ type: "unAuthorized" });
    } else if (error && error.status >= 500) {
      showErrorPopup({ type: "createContainerRetry", retryAction: callback });
    } else if (error && error.status === 400) {
      showErrorPopup({ type: "apiError", apiMessage: error?.data?.detail });
    } else {
      showErrorPopup({ type: "createContainerError" });
    }
  };

  // toast messages
  const toastMessage = ({ msg, success }) => {
    setToast({
      title: null,
      msg: tHTML(msg, {
        unsafe: true,
      }),
      styleClass: "tos-pos",
      delay: 3000,
      autoHide: true,
      toastImg: `${process.env.PUBLIC_URL}/images/${success ? "success.svg" : "generic-error-icon.svg"
        }`,
      show: true,
    });
  };

  return (
    <div className="ogaWrapper__bdy mx-1">
      <ConfirmPopUp
        isModalshow={isComfirmPopupVisible}
        dialogMsg={
          <p className="content__p mb-0">
            {t(
              "This will reset all the entered values. Are you sure you want to Cancel?"
            )}
          </p>
        }
        title={t("Cancel")}
        positiveButtonText={t("Yes, Cancel")}
        negativeButtonText={t("No")}
        positiveButtonHandle={handleConfirmed}
        negativeButtonHandle={handleCanceled}
      />
      <div className="mt-3">
        {selectedSpaId === "preferences" && <div className="preference-heading d-flex flex-column justify-content-start border-bottom mb-4">
          <span role="button" className="goto__link" tabIndex='0' aria-label="back" onClick={() => {setConfirmedVisible(true)}}>
              <img src={`${process.env.PUBLIC_URL}/images/back.svg`} className="mr-2" />Back
          </span>
          <h1 className='pt-2' >Preferences center</h1>
        </div>}
        <div className={`col-12 col-lg-${isActiveHub && selectedSpaId !== "preferences" ? "5" : "7"} mx-auto px-0`}>
          <div className={`instructor ${selectedSpaId === "preferences" ? 'preference_wrapper' : 'user_profile_wrapper'}`} aria-label="Preferences Section Started" tabIndex="0">
            {selectedSpaId === "preferences" &&
              <UserPreference
                popupConfig={{
                  toastMessage,
                  setConfirmedVisible: (isVisible) =>
                    setConfirmedVisible(!!isVisible),
                }}
              />}
            {selectedSpaId === "userProfile" &&
              <UserProfile
                handleAPIError={handleAPIError}
                isActiveHub
                popupConfig={{
                  toastMessage,
                  setLoaderState,
                  setConfirmedVisible: (isVisible) =>
                    setConfirmedVisible(!!isVisible),
                }}
              />}
            {selectedSpaId === "updatePassword" &&
              <ChangePassword
                handleAPIError={handleAPIError}
                popupConfig={{
                  toastMessage,
                  setLoaderState,
                  setConfirmedVisible: (isVisible) =>
                    setConfirmedVisible(!!isVisible),
                }}
              />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserWrapper;
