import React, { useContext } from "react";
import { PreferenceOptions } from "common-components-spa";
import { AppContext } from "src/App";

// css for user profile
import "./UserPreference.scss";

const UserPreference = ({
  handleAPIError,
  popupConfig: { toastMessage, setConfirmedVisible },
}) => {
  const { setSelectedSpaId } = useContext(AppContext);

  const handleUpdate = () => {
    toastMessage({
      msg: "Preferences updated Successfully !!!",
      success: true,
    });
    // setSelectedSpaId('urn:pearson:gps:spa:landingspaid')
  }

  return <PreferenceOptions onCancel={() => setConfirmedVisible(true)} onSuccess={() => handleUpdate()} isProgress={true} />
};

// noop funtion
const noop = () => { };

UserPreference.defaultProps = {
  handleAPIError: noop,
  popupConfig: {
    toastMessage: noop,
    // setLoaderState: noop,
    setConfirmedVisible: noop,
  },
};

export default UserPreference;